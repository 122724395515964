import { getClusters, getFarmLots, getSubClusters } from "../actions/farmManagement.actions";
import {
  getAttendedToSupplies,
  getNotAttendedToSupplies,
} from "../actions/collections.action";
import { createSlice } from "@reduxjs/toolkit";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  clusters: {
    data: [],
    total: 0,
    loading: false,
  },
  subClusters: {
    data: [],
    total: 0,
    loading: false,
  },
  farmLots: {
    data: [],
    total: 0,
    loading: false,
  }
};

const farmManagementSlice = createSlice({
  name: "farm-management",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFarmLots.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.farmLots.loading = false;
        state.farmLots.data = payload?.data || [];
        state.farmLots.total = payload?.total || 0;
      })
      .addCase(getFarmLots.pending, (state, action) => {
        // console.log("pending");
        state.farmLots.loading = true;
      })
      .addCase(getFarmLots.rejected, (state, { error }) => {
        // console.log("rejected");
        state.farmLots.loading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getClusters.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.clusters.loading = false;
        state.clusters.data = payload || [];
        state.clusters.total = payload?.length || 0;
      })
      .addCase(getClusters.pending, (state, action) => {
        // console.log("pending");
        state.clusters.loading = true;
      })
      .addCase(getClusters.rejected, (state, { error }) => {
        // console.log("rejected");
        state.clusters.loading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getSubClusters.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.subClusters.loading = false;
        state.subClusters.data = payload || [];
        state.subClusters.total = payload?.length || 0;
      })
      .addCase(getSubClusters.pending, (state, action) => {
        // console.log("pending");
        state.subClusters.loading = true;
      })
      .addCase(getSubClusters.rejected, (state, { error }) => {
        // console.log("rejected");
        state.subClusters.loading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getAttendedToSupplies.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoading = false;
        state.supplies = payload?.supplies?.data || [];
        state.total = payload?.supplies?.total || 0;
      })
      .addCase(getAttendedToSupplies.pending, (state, action) => {
        // console.log("pending");
        state.isLoading = true;
      })
      .addCase(getAttendedToSupplies.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getNotAttendedToSupplies.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoading = false;
        state.supplies = payload?.supplies?.data || [];
        state.total = payload?.supplies?.total || 0;
      })
      .addCase(getNotAttendedToSupplies.pending, (state, action) => {
        // console.log("pending");
        state.isLoading = true;
      })
      .addCase(getNotAttendedToSupplies.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      });
  },
});

export default farmManagementSlice;
