import { createSlice } from "@reduxjs/toolkit";
import {
  getTransactions,
} from "../actions/payments.action";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  transactions: {
    isLoading: false,
    total: 0,
    data: [],
    categories: []
  },
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.fulfilled, (state, { payload }) => {
        // console.log("fulfilled", payload);
        state.transactions.isLoading = false;
        state.transactions.data = payload?.ledgers?.data || [];
        state.transactions.total = payload?.ledgers?.total || 0;
      })
      .addCase(getTransactions.pending, (state, action) => {
        // console.log("pending");
        state.transactions.isLoading = true;
      })
      .addCase(getTransactions.rejected, (state, { error }) => {
        // console.log("rejected");
        state.transactions.isLoading = false;
        // notify(error.message, { type: "error" });
      })
  },
});

export default paymentSlice;
