import React from "react";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { theme } from "../../context/ThemeProvider";
import { IconButton } from "@mui/material";

const TableControls = ({
  nextHandler,
  prevHandler,
  limitHandler,
  page,
  totalRecords = 0,
  limit = 1,
  showLimit = true,
  hideOnMobile = false,
  currentLimit,
  limitOptions = [15, 25, 50, 100],
}) => {
  limit = Math.min(limit, totalRecords);
  const totalPages = Math.ceil(totalRecords / limit);
  const currentPage = (page - 1) * limit + 1;
  const pageRecordSize =
    limit * page > totalRecords ? totalRecords : limit * page;
  return (
    <div
      className={`flex justify-end items-center table-controls ${
        hideOnMobile ? "hide-on-mobile" : ""
      } `}
    >
      {!showLimit || showLimit === "false" || showLimit === false ? null : (
        <div className="filter-input mr-5">
          <label>Rows per page: </label>
          <select value={currentLimit} onChange={limitHandler}>
            {limitOptions
              .filter(
                (eachOption, index) =>
                  Number(eachOption) < Number(totalRecords) ||
                  (index === 0 && page === 1)
              )
              .map((eachOption, index) => (
                <option key={index} value={`${eachOption}`}>
                  {eachOption}
                </option>
              ))}
          </select>
        </div>
      )}

      <div className="flex items-center text-green-500">
        <p className="px-4">
          Viewing {currentPage} - {pageRecordSize} of {totalRecords}
        </p>
        <IconButton disabled={page <= 1} onClick={prevHandler}>
          <IoIosArrowBack
            style={{
              fontSize: "18px",
              color: page <= 1 ? "#aaa8" : theme.palette.primary.main,
            }}
          />
        </IconButton>
        <IconButton disabled={page >= totalPages} onClick={nextHandler}>
          <IoIosArrowForward
            style={{
              fontSize: "18px",
              color: page >= totalPages ? "#aaa8" : theme.palette.primary.main,
            }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default TableControls;
