import { createSlice } from "@reduxjs/toolkit";
import { notify } from "../../utils/toastNotification";
import { getAllCashAdvance } from "../actions/cashAdvance.action";
import {
  clearStorage,
  getAuthUser,
  getToken,
} from "../../utils/storage";

const initialState = {
  accessToken: getToken(),
  userProfile: getAuthUser(),
  isLoading: false,
  allCashAdvanceHistory: {
    isLoading: false,
  },
};

const cashAdvanceSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      state.userProfile = {};
      window.location.href = "/";
      clearStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCashAdvance.pending, (state, action) => {
      state.allCashAdvanceHistory.isLoading = true;
    });
    builder.addCase(getAllCashAdvance.fulfilled, (state, action) => {
      state.allCashAdvanceHistory.isLoading = false;
      state.allCashAdvanceHistory = action.payload;
    });
    builder.addCase(getAllCashAdvance.rejected, (state, action) => {
      state.allCashAdvanceHistory.isLoading = false;
    });

  },
});

export default cashAdvanceSlice;
