import { createSlice } from "@reduxjs/toolkit";
import { fetchSMSHistory, sendSMS } from "../actions/communication.action";

const initialState = {
  client: {
    isLoading: false,
    messages: [],
  },
  admin: {
    isLoading: false,
    messages: [],
  }
};

const communicationSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSMSHistory.pending, (state, action) => {
        state.client.isLoading = true;
      })
      .addCase(fetchSMSHistory.fulfilled, (state, { payload }) => {
        state.client.isLoading = false;
        state.client.messages = payload || [];
      })
      .addCase(fetchSMSHistory.rejected, (state, { error }) => {
        state.client.isLoading = false;
      })
      .addCase(sendSMS.pending, (state, action) => {
        state.admin.isLoading = true;
      })
      .addCase(sendSMS.fulfilled, (state, { payload }) => {
        state.admin.isLoading = false;
        state.admin.messages = payload || [];
      })
      .addCase(sendSMS.rejected, (state, { error }) => {
        state.admin.isLoading = false;
      });
  }
});

export default communicationSlice;