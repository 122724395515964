import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosServices from "../../services/axiosServices";
import { createQuery } from "../../utils/createQuery";
import AppSlice from "../slices/App.slice";
import { errorHandler } from "../../utils/actionsErrorHandler";

export const getStates = createAsyncThunk(
  "app/getStates",
  async (queryParams = {}, thunkAPI) => {
    // console.log(localStorage.getItem('states'));
    if (!sessionStorage.getItem("states")) {
      try {
        const response = await axiosServices.get(
          `/v3/all/states${createQuery(queryParams)}`
        );
        // console.log(response && response.success);
        const objectifiedStates = {};

        if (response?.result?.states) {
          response.result.states.forEach((state) => {
            objectifiedStates[state.state_id] = state;
          });
        }

        // dispatch({
        //   type: SET_STATES,
        //   payload: {
        //     states: response.result?.states,
        //     localStates: objectifiedStates,
        //   },
        // });

        return {
          states: response?.result?.states,
          localStates: objectifiedStates,
        };
      } catch (error) {
        console.log(error);
        errorHandler(error);
        throw error;
      }
    }
    // else {
    //   dispatch({
    //     type: GET_STATES,
    //   });
    // }
  }
);

export const getRoles = async (options = {}, setRoles = () => {}) => {
  try {
    const response = await axiosServices.get(`/v3/all/roles`);

    if (response?.result?.roles) {
      const retValue = options?.filterByAuthority
        ? response.result.roles.filter((role) => {
            return options?.authUser?.myRole?.role !== "Admin"
              ? ["Project Manager", "Agent"].includes(role.role)
              : true;
          })
        : response.result.roles;
      setRoles(retValue);
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getLocalGovt = createAsyncThunk("app/getLocalGovt", async (stateId, thunkAPI) => {
  // console.log(localStorage.getItem('states'));
  try {
    const response = await axiosServices.get(
      `/v3/all/states/${stateId}/locals`
    );
    return  {
      localGovt: response?.result?.locals,
    };
  } catch (error) {
    console.log(error);
    errorHandler(error);
    throw error;
  }
});

export const getAllServices = createAsyncThunk("app/getAllServices", async (thunkAPI) => {
  // console.log(localStorage.getItem('states'));
  try {
    const response = await axiosServices.get(`/v3/services`);
    // console.log(response && response.success);
    // if (response && response.success) {
    //   dispatch({
    //     type: SET_SERVICES,
    //     payload: {
    //       services: response.result?.services,
    //     },
    //   });
    // }
    return {
      services: response?.result?.services,
    };
  } catch (error) {
    console.log(error);
    errorHandler(error);
    throw error;
  }
});

// export const getPermission = async (dispatch) => {
//   try {
//     const response = await axiosServices.get(
//       `/v3/permissions?groupPermission=yes`
//     );
//     if (response?.result?.permissions) {
//       dispatch({
//         type: SET_PERMISSIONS,
//         payload: {
//           permissions: response.result?.permissions,
//         },
//       });
//     }
//   } catch (error) {
//     console.log(error);
//     return;
//   }
// };

export default AppSlice.actions;
