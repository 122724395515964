import { createSlice } from "@reduxjs/toolkit";
import {
  getCommodities,
  getSuppliers,
  getFarmers,
  getSingleSupplier,
  getSingleFarmer,
  getSingleFarmerAgro,
  getUploadReport,
} from "../actions/supplier.action";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  client: {
    suppliers: [],
    isLoading: false,
    total: 0,
    commodities: [],
    isLoadingCommodities: false,
  },
  farmers: {
    data: [],
    isLoading: false,
    total: 0,
    commodities: [],
    isLoadingCommodities: false,
  },
  singleSupplier: {
    isLoading: false,
    supplier: {},
  },
  singleFarmer: {
    isLoading: false,
    farmer: {},
  },
  singleFarmerAgro: {
    isLoading: false,
    farmer: {},
  },
  uploadHistory: [],
};

const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliers.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.client.isLoading = false;
        state.client.suppliers = payload?.suppliers?.data || [];
        state.client.total = payload?.suppliers?.total || 0;
      })
      .addCase(getSuppliers.pending, (state, action) => {
        // console.log("pending");
        state.client.isLoading = true;
      })
      .addCase(getSuppliers.rejected, (state, { error }) => {
        // console.log("rejected");
        state.client.isLoading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getFarmers.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        // console.log("farmers", payload);
        state.farmers.isLoading = false;
        state.farmers.data = payload?.farmers?.data || [];
        state.farmers.total = payload?.farmers?.total || 0;
      })
      .addCase(getFarmers.pending, (state, action) => {
        // console.log("pending");
        state.farmers.isLoading = true;
      })
      .addCase(getFarmers.rejected, (state, { error }) => {
        // console.log("rejected");
        state.farmers.isLoading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getCommodities.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoadingCommodities = false;
        state.commodities = payload?.commodities || [];
      })
      .addCase(getCommodities.pending, (state, action) => {
        // console.log("pending");
        state.isLoadingCommodities = true;
      })
      .addCase(getCommodities.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoadingCommodities = false;
        // notify(error.message, { type: "error" });
      })

      .addCase(getSingleSupplier.pending, (state, action) => {
        state.singleSupplier.isLoading = true;
      })
      .addCase(getSingleSupplier.fulfilled, (state, action) => {
        state.singleSupplier.supplier = action.payload;
        state.singleSupplier.isLoading = false;
      })
      .addCase(getSingleSupplier.rejected, (state, action) => {
        state.singleSupplier.isLoading = false;
      })

      .addCase(getSingleFarmer.pending, (state, action) => {
        state.singleFarmer.isLoading = true;
      })
      .addCase(getSingleFarmer.fulfilled, (state, action) => {
        state.singleFarmer.supplier = action.payload;
        state.singleSupplier.isLoading = false;
      })
      .addCase(getSingleFarmer.rejected, (state, action) => {
        state.singleFarmer.isLoading = false;
      })

      .addCase(getSingleFarmerAgro.pending, (state, action) => {
        state.singleFarmerAgro.isLoading = true;
      })
      .addCase(getSingleFarmerAgro.fulfilled, (state, action) => {
        state.singleFarmerAgro.supplier = action.payload;
        state.singleFarmerAgro.isLoading = false;
      })
      .addCase(getSingleFarmerAgro.rejected, (state, action) => {
        state.singleFarmerAgro.isLoading = false;
      })
      .addCase(getUploadReport.pending, (state, action) => {
        state.isLoading = true;
      }
      )
      .addCase(getUploadReport.fulfilled, (state, action) => {
        state.uploadHistory = action.payload;
        state.isLoading = false;
      })
      .addCase(getUploadReport.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default supplierSlice;
