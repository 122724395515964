import * as React from "react";
import { Breadcrumbs } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";

export default function CustomBreadCrumb({ breadcrumbs, ...rest }) {
  return (
    <Breadcrumbs
      maxItems={2}
      separator={<ArrowForwardIcon color="primary" fontSize="small" />}
      aria-label="breadcrumb"
      {...rest}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}
