import { createSlice } from "@reduxjs/toolkit";
import { getLocalGovt, getStates } from "../actions/app.action";

const AppSlice = createSlice({
  name: "app",
  initialState: {
    menuSelected: -1,
    states: {},
    localGovt: [],
    permissions: [],
    services: [],
  },
  reducers: {
    setCurrentDrawerMenu(state, action) {
      // console.log(state, action);
      localStorage.setItem("menuSelected", action.payload);

      return {
        ...state,
        menuSelected: action.payload,
      };
    },
    setStates(state, action) {
      // console.log(payload);
      sessionStorage.setItem("states", JSON.stringify(action.payload.localStates));
      return {
        ...state,
        states: {
          ...state.states,
          states: action.payload.states,
          localStates: action.payload.localStates
        },
      };
    },
    setLocalGovt(state, action) {
      // console.log(payload);
      sessionStorage.setItem("localGovt", JSON.stringify(action.payload.localGovt));
      return {
        ...state,
        localGovt: action.payload.localGovt,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStates.fulfilled, (state, { payload }) => {
        state.states = payload || {};
      })
      .addCase(getStates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStates.rejected, (state) => {
        state.loading = false;
      })

      builder
      .addCase(getLocalGovt.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.localGovt = payload?.localGovt ?? [];
      })
      .addCase(getLocalGovt.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocalGovt.rejected, (state) => {
        state.loading = false;
      })
  }
});

export default AppSlice;
