import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "../../services/axiosServices";
import axiosServices, { useAxiosService } from "../../services/axiosServices";
// import adminAxiosService from "../../services/adminAxiosService";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";
import { convertToFormData } from "../../utils/convertJSONToFormData";
// import { CROP_GATE_ACCESS_KEY } from "../../configs/api";
import axiosServicesCropGate from "../../services/cropgateAxiosService";
import { notify } from "../../utils/toastNotification";
import { toast } from "react-toastify";

export const getFarmers = createAsyncThunk(
  "supplier/getFarmers",
  async (
    queryParams = {
      page: 1,
      limit: 20,
    },
    thunkAPI
  ) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(
        `/v1/farmers${queryParamString}`
      );
      console.log("response", response);
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

// v3/farmers/bulk-upload/report
export const getUploadReport = createAsyncThunk(
  "supplier/getUploadReport",
  async (thunkAPI) => {
    try {
      const response = await useAxiosService("formData")({
        method: "GET",
        url: `/v3/farmers/bulk-upload/report`,
      });
      console.log("response", response);
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleFarmer = createAsyncThunk(
  "supplier/getSingleFarmer",
  async (farmerId, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.get(
        `/v1/farmers/${farmerId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getSingleFarmerAgro = createAsyncThunk(
  "supplier/getSingleFarmerAgro",
  async (farmerId, thunkAPI) => {
    try {
      const response = await axiosServices.get(
        `/v3/farmers/${farmerId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const updateFarmer = createAsyncThunk(
  "supplier/updateFarmer",
  async ({ farmerId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.patch(
        `/v1/farmers/${farmerId}`,
        payload
      );
      
      toast.success("Farmer updated successfully");
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const uploadFarmers = createAsyncThunk(
  "supplier/uploadFarmers",
  async (payload, thunkAPI) => {
    try {
      payload = convertToFormData(payload);
      const response = await useAxiosService("formData")({
        method: "POST",
        url: `/v3/farmers/bulk-upload`,
        data: payload,
        // headers: {
        //   "crop-gate-access-key": CROP_GATE_ACCESS_KEY,
        // },
      });

      // console.log("here",response);
      if (response?.success && response?.result) {
        notify(response?.message, { type: "success" });
      }
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

// export const bulkUploadFarmers = (data) => async (dispatch) => {
//   try {
//     data = convertToFormData(data);
//     const res = await useAxiosService("formData")({
//       method: "post",
//       url: `/v3/farmers/bulk-upload`,
//       data,
//     });

//     if (res?.success && res?.result) {
//       notify("Farmers uploaded successfully", { type: "success" });
//       dispatch(getFarmers({ page: 1 }));
//     }
//   } catch (error) {
//     console.log(error);
//     errorHandler(error, "Failed to add farmer");
//     return error;
//   }
// };

export const downloadFarmerTemplate = async () => {
  try {
    axiosService({
      url: "/v3/templates/farmers",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      // console.log([response.data]);
      const url = window.URL.createObjectURL(
        new Blob([response], {
          encoding: "UTF-8",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "FARMER UPLOAD SAMPLE.xlsx"); //or any other extension
      link.click();
      window.URL.revokeObjectURL(url);
    });
    // window.open(`${BASE_URL}/templates/farmers`, "_blank");
  } catch (error) {
    console.log(error);
    errorHandler(error);
    return error;
  }
};

export const getSuppliers = createAsyncThunk(
  "supplier/getSuppliers",
  async (
    queryParams = {
      page: 1,
      limit: 20,
    },
    thunkAPI
  ) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(
        `/v1/suppliers${queryParamString}`
      );
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleSupplier = createAsyncThunk(
  "supplier/getSingleSupplier",
  async (supplierId, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.get(
        `/v1/suppliers/${supplierId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addSupplier = createAsyncThunk(
  "supplier/addSupplier",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.post(
        `/v1/suppliers`,
        payload
      );
      
      toast.success("Supplier added successfully");
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const addFarmer = createAsyncThunk(
  "supplier/addFarmer",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.post(`/v1/farmers`, payload);
      
      toast.success("Farmer added successfully");
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async ({ supplierId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.patch(
        `/v1/suppliers/${supplierId}`,
        payload
      );
      
      toast.success("Supplier updated successfully");
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getCommodities = createAsyncThunk(
  "supplier/getCommodities",
  async ({ supplierId, ...queryParams }, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(
        `/v1/supplier/${supplierId}/commodities${queryParamString}`
      );
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleCommodity = createAsyncThunk(
  "supplier/getSingleCommodity",
  async (supplierId, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.get(
        `/v1/suppliers/${supplierId}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const addCommodity = createAsyncThunk(
  "supplier/addCommodity",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.post(
        `/v1/supplier/commodities`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const updateCommodity = createAsyncThunk(
  "supplier/updateCommodity",
  async ({ commodityId, ...payload }, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.patch(
        `/v1/suppliers/${commodityId}`,
        payload
      );
      // console.log(response);
      return response;
    } catch (error) {
      // errorHandler(error, "An error occurred");
      throw error;
    }
  }
);
