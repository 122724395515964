import React, { useRef, useMemo, useEffect, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import Modal from "react-modal";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import "../FarmActivities/FarmActivities.scss";
import { getPoints } from "../../../helpers/getPoints";
import axiosServices from "../../../services/axiosServices";
import moment from "moment";
import VerificationMap from "../FarmActivities/VerificationMap";
import ImagesCard from "./ImagesCard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minHeight: "70vh",
    // maxWidth: "70vw",
    minWidth: "40vw",
    padding: "0px 20px",
    width: "500px",
  },
};

const ActivityCard = ({
  setShowActivityModal,
  getCords,
  activityName,
  taskId,
  farmerId,
  setTaskId,
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    setShowActivityModal(false);
    setTaskId("");
  }

  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [activityData, setActivityData] = useState([]);

  const points =
    activityData?.activity_farm?.points &&
    activityData?.activity_farm?.points.map((pair) => {
      const obj = {
        lat: pair.lat,
        lng: pair.lng,
      };
      return obj;
    });

  useEffect(() => {
    const getTaskDetails = async () => {
      const body = {
        farmer_id: farmerId,
        task_id: taskId,
      };
      try {
        setIsLoading(true);
        const res = await axiosServices.post(`/v3/farm/activity/fetch`, body);
        // console.log("getTaskDetails", res?.result?.activity);
        setIsLoading(false);
        setActivityData(res?.result?.activity);
      } catch (error) {
        setIsLoading(false);
      }
    };
    getTaskDetails();
  }, [taskId]);

  return (
    <div>
      {/* <svg
        onClick={openModal}
        style={{ cursor: "pointer" }}
        width='20'
        height='20'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13 2.5C18.775 2.5 23.5 7.225 23.5 13C23.5 18.775 18.775 23.5 13 23.5C7.225 23.5 2.5 18.775 2.5 13C2.5 7.225 7.225 2.5 13 2.5ZM13 0.75C6.2625 0.75 0.75 6.2625 0.75 13C0.75 19.7375 6.2625 25.25 13 25.25C19.7375 25.25 25.25 19.7375 25.25 13C25.25 6.2625 19.7375 0.75 13 0.75Z'
          fill='#14B6A7'
        />
        <path
          d='M20 12.125H13.875V6H12.125V12.125H6V13.875H12.125V20H13.875V13.875H20V12.125Z'
          fill='#14B6A7'
        />
      </svg> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>

          <IconButton onClick={closeModal}>
            <CloseIcon className='closeicon' />
          </IconButton>
        </div> */}
        <div className='activitycard'>
          <ul>
            <li
              style={{
                color: tab1 ? "#fff" : "#000",
                background: tab1 ? "#14B6A7" : "none",
                fontWeight: tab1 ? "bold" : "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                setTab1(true);
                setTab2(false);
                setTab3(false);
              }}
            >
              Activity Card
            </li>
            <li
              style={{
                color: tab2 ? "#fff" : "#000",
                background: tab2 ? "#14B6A7" : "none",
                fontWeight: tab2 ? "bold" : "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                setTab1(false);
                setTab2(true);
                setTab3(false);
              }}
            >
              Map View
            </li>
            <li
              style={{
                color: tab3 ? "#fff" : "#000",
                background: tab3 ? "#14B6A7" : "none",
                fontWeight: tab3 ? "bold" : "normal",
                cursor: "pointer",
              }}
              onClick={() => {
                setTab1(false);
                setTab2(false);
                setTab3(true);
              }}
            >
              View Images
            </li>
          </ul>
        </div>
        {isLoading || !activityData ? (
          <p>Loading...</p>
        ) : (
          <>
            {tab1 && (
              <div className='activity-card'>
                <Box sx={{ width: "100%", padding: "20px 0" }}>
                  <h3>Activity status</h3>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={5}>
                      <Box>
                        <p className='activity-title'>Category</p>
                        <p className='activities'>
                          {activityData?.activity_category?.category}
                        </p>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Method</p>
                        <p className='activities'>Mechanized</p>
                      </Box>
                    </Grid> */}
                    <Grid item xs={4}>
                      <Box>
                        <p className='activity-title'>Task</p>
                        <p className='activities'>
                          {activityData?.activity_task?.task}
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Status</p>
                        <p className='activities'>{activityData?.status}</p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Expected Date</p>
                        <p className='activities'>
                          {activityData?.activity_task?.due_date}
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Actual Date</p>
                        <p className='activities'>{activityData?.date}</p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p lassName='activity-title'>Tracked Date</p>
                        <p className='activities'>
                          {moment(activityData?.activity_task?.created).format(
                            "yy-MM-DD"
                          )}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    padding: "20px 0",
                    borderTop: "1px solid rgba(108, 117, 125, 0.27)",
                    borderBottom: "1px solid rgba(108, 117, 125, 0.27)",
                  }}
                >
                  <h3>Farm Info</h3>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Crop</p>
                        <p className='activities'></p>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <p className='activity-title'>Total Size (Ha)</p>
                        <p className='activities'>
                          {activityData?.activity_farmer?.farm_size}
                        </p>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Size Captured</p>
                        <p className='activities'>223.9</p>
                      </Box>
                    </Grid> */}
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>State</p>
                        <p className='activities'>
                          {activityData?.activity_farm?.state?.name}
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>LGA</p>
                        <p className='activities'>
                          {activityData?.activity_farm?.lga?.local_name}
                        </p>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Cluster</p>
                        <p className='activities'>Adamawa Cluster</p>
                      </Box>
                    </Grid> */}
                    <Grid item xs={5}>
                      <Box>
                        <p className='activity-title'>Address</p>
                        <p className='activities'>
                          {/* #34 Palms Way Island, Lagos */}
                          {activityData?.activity_farm?.address}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ width: "100%", padding: "20px 0" }}>
                  <h3>Agent Info</h3>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <Box>
                        <p className='activity-title'>Farmer</p>
                        <p className='activities'>
                          {activityData?.activity_agent?.first_name +
                            " " +
                            activityData?.activity_agent?.last_name}
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Phone Number</p>
                        <p className='activities'>
                          {activityData?.activity_agent?.mobile}
                        </p>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Farmlot</p>
                        <p className='activities'>223.9</p>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <p className='activity-title'>Farmlot</p>
                        <p className='activities'>Fm-2231</p>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                      <Box>
                        <p className='activity-title'>Comment</p>
                        <p className='activities'>{activityData?.comment}</p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}

            {tab2 && (
              <div className='map-card'>
                <VerificationMap points={points} />
              </div>
            )}

            {tab3 && (
              <div className='image-card'>
                <ImagesCard images={activityData?.images} />
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ActivityCard;
