import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosService from "../../services/cropgateAxiosService";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";

export const getTransactions = createAsyncThunk(
  "payment/getTransactions",
  async (
    queryParams = {
      page: 1,
      limit: 20,
    },
    thunkAPI
  ) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosService.get(`/v1/ledger/transactions${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);
