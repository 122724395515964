import { toast } from "react-toastify";
const defaultOptions = {
  type: "info",
  position: toast.POSITION.TOP_RIGHT,
};

export const POSITIONS = toast.POSITION;

/**
 *
 * @param {string} message
 * @param {{
 *  type?: 'info' | 'error' | string;
 *  position?: toast.POSITION
 * }} options
 * @returns
 */
export const notify = (message, options = defaultOptions) => {
  const { type, ...rest } = options;

  switch (type) {
    case "success":
      return toast.success(message, rest);

    case "info":
      return toast.info(message, rest);

    case "error":
      return toast.error(message, rest);

    case "warn":
      return toast.warn(message, rest);

    default:
      return toast[type] ? toast[type](message, rest) : toast(message, rest);
  }
};
