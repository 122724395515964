import { createAsyncThunk } from "@reduxjs/toolkit";
// import adminAxiosService from "../../services/adminAxiosService";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";
import axiosServicesCropGate from "../../services/cropgateAxiosService";

export const getSupplies = createAsyncThunk(
  "collections/getSupplies",
  async (queryParams, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(`/v1/supplies${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      // errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getAttendedToSupplies = createAsyncThunk(
  "collections/getAttendedToSupplies",
  async (queryParams, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(`/v1/attended/to/supplies${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getNotAttendedToSupplies = createAsyncThunk(
  "collections/getNotAttendedToSupplies",
  async (queryParams, thunkAPI) => {
    try {
      const queryParamString = createQuery(queryParams);

      const response = await axiosServicesCropGate.get(`/v1/not-attended/to/supplies${queryParamString}`);
      // console.log({ response });
      return response?.result;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

export const getSingleSupplyInfo = createAsyncThunk(
  "collections/getSingleSupplyInfo",
  async (supplyId, thunkAPI) => {
    try {
      const response = await axiosServicesCropGate.get(`/v1/supplies/${supplyId}`);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);


// export const getApprovedVendors = createAsyncThunk(
//   "vendors/getApprovedVendors",
//   async (thunkAPI) => {
//     try {
//       const queryParamString = createQuery({
//         page: 1,
//         limit: 20,
//       });

//       const response = await vendorAxiosService.get(
//         `/vendor/all${queryParamString}`
//       );
//       // console.log({ response });
//       return response;
//     } catch (error) {
//       // errorHandler(error, "An error occurred");
//       throw error;
//     }
//   }
// );

// export const getAdminVendors = createAsyncThunk(
//   "vendors/getAdminVendors",
//   async (thunkAPI) => {
//     const response = await adminAxiosService.get(`/vendor/all`);
//     console.log(response);
//     return response;
//   }
// );

// export const addVendor = createAsyncThunk(
//   "vendors/addVendor",
//   async (payload, thunkAPI) => {
//     try {
//       const response = await vendorAxiosService.post(
//         `/vendor/create/commodified`,
//         payload
//       );
//       // console.log(response);
//       return response;
//     } catch (error) {
//       // errorHandler(error, "An error occurred");
//       throw error;
//     }
//   }
// );
