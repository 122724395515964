import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_URL, BASE_URL } from "../../configs/api";
// import authAxiosService from "../../services/authAxiosService";
import axiosServices from "../../services/axiosServices";
import { setToken } from "../../utils/storage";
import { toast } from "react-toastify";
import { errorHandler } from "../../utils/actionsErrorHandler";

export const login = createAsyncThunk(
  "auth/loginStatus",
  async (payload, { dispatch }) => {
    try {
      const config = {};
      const response = await axios.post(
        `${AUTH_URL}/v3/login`,
        payload,
        config
      );
      setToken(response?.data?.token);
      await dispatch(getUserProfile());
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "auth/getUserProfile",
  async (thunkAPI) => {
    try {
      const res = await axiosServices.get(`/v3/user`);
      const userData = res?.result?.user;
      return userData;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const registerApi = createAsyncThunk(
  "auth/register",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(`${BASE_URL}/v3/register`, payload);
      // console.log(response);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

export const createPassword = createAsyncThunk(
  "auth/createPassword",
  async (payload, thunkAPI) => {
    const { token } = payload;
    try {
      const response = await axiosServices.post(`/confirm/email/${token}`, {
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      });
      // console.log(response);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error?.response?.data?.message, "error");
      throw error;
    }
  }
);

export const forgottenPassword = createAsyncThunk(
  "auth/forgottenPassword",
  async (payload, thunkAPI) => {
    try {
      // const config = {};
      const response = await axiosServices.post(
        `/forgot/password`,
        payload
        // config
      );
      // console.log("FORGOT_PASSWORD_URL", response);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw error;
    }
  }
);

export const resetPasswordApi = createAsyncThunk(
  "auth/resetPassword",
  async (payload, thunkAPI) => {
    try {
      // const config = {};
      const response = await axiosServices.post(
        `/reset/password/${payload.token}`,
        payload
        // config
      );
      // console.log("FORGOT_PASSWORD_URL", response);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw error;
    }
  }
);

export const getAllPermission = createAsyncThunk(
  "auth/getAllPermission",
  async (queryParams = {}, thunkAPI) => {
    try {
      const response = await axiosServices.get(
        `/v3/permissions?groupPermission=yes`
      );

      return response.result?.permissions;
    } catch (error) {
      // console.log(error);
      // errorHandler(error);
      throw error;
    }
  }
);
