// import { getAllLoanHistory } from './../actions/dashboard.action';
import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDetails, getFarmActivitiesNew } from "../actions/dashboard.action";
// import { notify } from '../../utils/toastNotification';

const initialState = {
  client: {
    isLoading: false,
  },
  admin: {
    isLoading: false,
  },
  dashboard: {
    isLoading: false,
    lots: [],
    total: 0,
    totalYield: 0,
    totalAcreage: 0,
    numberOfFarmers: 0,
  },
  farmActivities: {
    isLoadingFarmActivities: false,
    activities: [],
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDetails.fulfilled, (state, { payload }) => {
        // console.log("fulfilled", payload);
        state.dashboard.isLoading = false;
        state.dashboard.lots = payload?.lots || [];
        state.dashboard.totalAcreage = payload?.total_acreage || 0;
        state.dashboard.totalYield = payload?.total_yield || 0;
        state.dashboard.numberOfFarmers = payload?.number_of_farmers || 0;
        state.dashboard.total = payload?.lots?.length || 0;
      })
      .addCase(getDashboardDetails.pending, (state, action) => {
        // console.log("pending");
        state.dashboard.isLoading = true;
      })
      .addCase(getDashboardDetails.rejected, (state, { error }) => {
        // console.log("rejected");
        state.dashboard.isLoading = false;
        // notify(error.message, { type: "error" });
      });

    builder
      .addCase(getFarmActivitiesNew.fulfilled, (state, { payload }) => {
        // console.log("fulfilled", payload);
        state.farmActivities.isLoadingFarmActivities = false;
        state.farmActivities.activities = payload || [];
      })
      .addCase(getFarmActivitiesNew.pending, (state, action) => {
        // console.log("pending");
        state.farmActivities.isLoadingFarmActivities = true;
      })
      .addCase(getFarmActivitiesNew.rejected, (state, { error }) => {
        // console.log("rejected");
        state.farmActivities.isLoadingFarmActivities = false;
        // notify(error.message, { type: "error" });
      });
  },
});

export default dashboardSlice;
