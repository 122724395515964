export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL || "https://dev-payment-service.tradebuza.com";
export const AGRO_BASE_URL = process.env.REACT_APP_AGRO_BASE_URL || "https://dev-agro-service.tradebuza.com/api";
export const CROP_GATE_BASE_URL = process.env.REACT_APP_CROP_GATE_BASE_URL;
export const CROP_GATE_ACCESS_KEY = process.env.REACT_APP_CROP_GATE_ACCESS_KEY;
export const FORGOT_PASSWORD_URL = "https://dev-auth-service.tradebuza.com/api/account/mobile/forgot/password";
export const MONO_PUBLIC_KEY = process.env.REACT_APP_MONO_PUBLIC_KEY;

const config = {
    // BASE_URL: process.env.REACT_APP_BASE_URL,
    // AUTH_URL: process.env.REACT_APP_AUTH_URL,
    mapsKey: process.env.REACT_APP_MAP_KEY,
    weatherKey: process.env.REACT_APP_WEATHER_KEY,
    opeIssue: process.env.REACT_APP_OPE_ISSUE_URL,
}

export default config;