import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosServices from "../../services/axiosServices";
import cropgateAxiosService from "../../services/cropgateAxiosService";
import { toast } from "react-toastify";
import { errorHandler } from "../../utils/actionsErrorHandler";
import { createQuery } from "../../utils/createQuery";

export const getDashboardDetails = createAsyncThunk(
  "dashboard/getDashboardDetails",
  async (queryParams = {}, thunkAPI) => {
    try {
      const dashboardDetails = await axiosServices.get(
        `/v3/dashboard${createQuery(queryParams)}`
      );
      // console.log(dashboardDetails);
      return dashboardDetails;
    } catch (error) {
      console.log(error);
      errorHandler(error, "Error in fetching dashboard details");
      throw error;
    }
  }
);

export const addDirector = createAsyncThunk(
  "dashboard/addUser",
  async (payload, thunkAPI) => {
    try {
      const response = await cropgateAxiosService.post(
        `/v1/companyDirectors`,
        payload
      );
      // console.log(response);
      //   toast.success("User added successfully");
      //   window.location.href = "/crop-gate/user-management/users";
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      throw error;
    }
  }
);

// export const getFarmActivities = createAsyncThunk(
//   "dashboard/getFarmActivities",
//   async (queryParams = {}, thunkAPI) => {
//     try {
//       const farmActivities = await axiosServices.get(
//         `/v3/farm/activities/dashboard${createQuery(queryParams)}`
//       );
//       return farmActivities?.result;
//     } catch (error) {
//       errorHandler(error, "Error in fetching farm activities");
//       throw error;
//     }
//   }
// );

export const getFarmActivitiesNew = createAsyncThunk(
  "dashboard/getFarmActivities",
  async (queryParams = {}, thunkAPI) => {
    try {
      const farmActivities = await axiosServices.get(
        `/v4/config/farm/activities${createQuery(queryParams)}`
      );
      return farmActivities?.result;
    } catch (error) {
      errorHandler(error, "Error in fetching farm activities");
      throw error;
    }
  }
);
