import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

function ToggleSwitch({ labelStyle, switchColor = "primary" }) {
  const [checked, setChecked] = useState(false);

  // Initialize checked state based on localStorage
  useEffect(() => {
    const isSuperTenant = localStorage.getItem("isSuperTenant");
    setChecked(isSuperTenant === "ON");
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("isSuperTenant", "ON");
      window.location.reload();
    } else {
      localStorage.setItem("isSuperTenant", "OFF");
      window.location.reload();
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          color={switchColor} // We can omit this if we're styling manually below
          sx={{
            '& .MuiSwitch-switchBase.Mui-checked': {
                color: 'white', // Thumb color when the switch is checked (active)
            },
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#FFB82F', // Track color when the switch is checked (active)
                padding: '8px', // Track padding
            },
            '& .MuiSwitch-track': {
                backgroundColor: '#17CE6A', // Track color when the switch is not checked (inactive)
                padding: '8px', // Track padding
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: 'white', // Thumb color by default
            },
          }}
        />
      }
      label={checked ? "Super Tenant" : "Aggregator"}
      sx={{
        // Label styles
        "& .MuiFormControlLabel-label": {
          fontSize: labelStyle?.fontSize || "1rem",
          // Additional label style adjustments can be placed here
        },
      }}
    />
  );
}

export default ToggleSwitch;

