import { createSlice } from "@reduxjs/toolkit";
import { notify } from "../../utils/toastNotification";
import {
  getAllUsers,
  addUser,
  getUserById,
  getFarmers,
  getTenants,
  getAllServices,
  getSingleTenant,
  getRoles,
  getSourcingAgents,
  getMyTenants,
} from "../actions/userManagement.action";
import {
  clearStorage,
  getAuthUser,
  getToken,
  // setAuthUser,
  // setToken,
} from "../../utils/storage";
import { all } from "naija-state-local-government";

const initialState = {
  accessToken: getToken(),
  userProfile: getAuthUser(),
  isLoading: false,
  allUsers: {
    isLoading: false,
  },
  addUser: {
    isLoading: false,
  },
  userById: {
    isLoading: false,
  },
  farmers: {
    data: [],
    total: 0,
    loading: true,
  },
  sourcingAgents: {
    data: [],
    total: 0,
    loading: true,
  },
  allTenants: {
    data: [],
    total: 0,
    loading: true,
  },
  singleTenant: {
    data: {},
    loading: true,
  },
  allServices: {
    data: [],
    total: 0,
    loading: true,
  },
  allRoles: {
    data: [],
    loading: true,
  },
  myTenants: {
    data: [],
    total: 0,
    loading: true,
  },
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    logout(state, action) {
      state.userProfile = {};
      window.location.href = "/";
      clearStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.allUsers.isLoading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
      state.allUsers.isLoading = false;
    });
    builder.addCase(getAllUsers.rejected, (state) => {
      state.allUsers.isLoading = false;
    });

    builder.addCase(getFarmers.pending, (state) => {
      state.farmers.loading = true;
    });
    builder.addCase(getFarmers.fulfilled, (state, action) => {
      state.farmers.loading = false;
      state.farmers.data = action.payload?.data || [];
      state.farmers.total = action.payload?.total || 0;
    });
    builder.addCase(getFarmers.rejected, (state) => {
      state.farmers.loading = false;
    });

    builder.addCase(addUser.pending, (state) => {
      state.addUser.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.addUser = action.payload;
      state.addUser.isLoading = false;
      notify("User added successfully", { type: "success" });
      window.location.href = "/crop-gate/user-management/users";
    });
    builder.addCase(addUser.rejected, (state) => {
      state.addUser.isLoading = false;
    });

    builder.addCase(getUserById.pending, (state) => {
      state.userById.isLoading = true;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.userById.isLoading = false;
      state.userById = action.payload;
    });
    builder.addCase(getUserById.rejected, (state) => {
      state.userById.isLoading = false;
    });

    builder.addCase(getTenants.pending, (state) => {
      state.allTenants.loading = true;
    });
    builder.addCase(getTenants.fulfilled, (state, action) => {
      state.allTenants.loading = false;
      state.allTenants.data = action.payload || [];
      state.allTenants.total = action.payload?.total || 0;
    });
    builder.addCase(getTenants.rejected, (state) => {
      state.allTenants.loading = false;
    });

    builder.addCase(getAllServices.pending, (state) => {
      state.allServices.loading = true; // Fixed the correct loading state
    });
    builder.addCase(getAllServices.fulfilled, (state, action) => {
      state.allServices.loading = false;
      state.allServices.data = action.payload || [];
      state.allServices.total = action.payload?.total || 0;
    });
    builder.addCase(getAllServices.rejected, (state) => {
      state.allServices.loading = false;
    });

    builder.addCase(getSingleTenant.pending, (state) => {
      state.singleTenant.loading = true; // Fixed the correct loading state
    });
    builder.addCase(getSingleTenant.fulfilled, (state, action) => {
      state.singleTenant.loading = false;
      state.singleTenant.data = action.payload || {};
    });
    builder.addCase(getSingleTenant.rejected, (state) => {
      state.singleTenant.loading = false;
    });

    builder.addCase(getRoles.pending, (state) => {
      state.allRoles.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      console.log("api", action.payload);
      state.allRoles.loading = false;
      state.allRoles.data = action.payload || [];
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.allRoles.loading = false;
    });

    builder.addCase(getSourcingAgents.pending, (state) => {
      state.sourcingAgents.loading = true; // Fixed the correct loading state
    });
    builder.addCase(getSourcingAgents.fulfilled, (state, action) => {
      state.sourcingAgents.loading = false;
      state.sourcingAgents.data = action.payload || [];
      state.sourcingAgents.total = action.payload?.total || 0;
    });
    builder.addCase(getSourcingAgents.rejected, (state) => {
      state.sourcingAgents.loading = false;
    });

    // Adding getMyTenants case handlers
    builder.addCase(getMyTenants.pending, (state) => {
      state.myTenants.loading = true;
    });
    builder.addCase(getMyTenants.fulfilled, (state, action) => {
      state.myTenants.loading = false;
      state.myTenants.data = action.payload || [];
      state.myTenants.total = action.payload?.total || 0;
    });
    builder.addCase(getMyTenants.rejected, (state) => {
      state.myTenants.loading = false;
    });
  },
});


export default userManagementSlice;
