import { createAsyncThunk } from "@reduxjs/toolkit";
import paymentAxiosService from "../../services/paymentAxiosService";
import { errorHandler } from "../../utils/actionsErrorHandler";

export const getBanks = createAsyncThunk(
  "utility/getBanks",
  async (thunkAPI) => {
    try {
      const response = await paymentAxiosService.get(`/banks`);
      return response;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);

// : { bankCode: string; accountNumber: string }
export const getBankAccountName = createAsyncThunk(
  "utility/getBankAccountName",
  async (payload, thunkAPI) => {
    try {
      const response = await paymentAxiosService.post(
        `/disbursement/validate-account-number`,
        payload
      );
      // console.log(`Bank Details fetched`);
      return response?.data;
    } catch (error) {
      errorHandler(error, "An error occurred");
      throw error;
    }
  }
);
