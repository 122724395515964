import axios from "axios";
import { CROP_GATE_BASE_URL } from "../configs/api";

import { setMiddleWares } from "./index";

// Define your header here

const isSuperTenant = localStorage.getItem("isSuperTenant");

const customHeaders = {
  // Add your header key-value pairs here
  "Authorization": localStorage.getItem("access_token"),
  "crop-gate-agro-fusion": "yes",
  "X-SUPER-TENANT-MODE": isSuperTenant,
};

const createConfig = {
  baseURL: CROP_GATE_BASE_URL,
  headers: customHeaders // Set custom headers
};

const axiosServicesCropGate = axios.create(createConfig);

// Add a request interceptor to set headers for every request
axiosServicesCropGate.interceptors.request.use(
  config => {
    // You can modify headers here if needed
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const _getAxiosService = (contentType = "json") => {
  setMiddleWares(axiosServicesCropGate, contentType, CROP_GATE_BASE_URL);
  return axiosServicesCropGate;
};

// Call _getAxiosService() to initialize the axios instance
_getAxiosService();

export default axiosServicesCropGate;

export const useAxiosService = _getAxiosService;
