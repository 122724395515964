import moment from "moment";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AiOutlineEye as ViewIcon } from "react-icons/ai";
import React, { useState, useEffect, useMemo } from "react";

import { IconButton, Box, Typography, Link, Stack } from "@mui/material";

import ActivityCard from "./ActivityCard";
import Table from "../../../components/Table/PlainTable";
import CustomSelect from "../../../components/common/CustomSelect";
import TableActions from "../../../components/Table/TableControls";
import BreadCrumb from "../../../components/common/CustomBreadCrumbs";

import axiosServices from "../../../services/axiosServices";
import "./AgentActivities.scss";

const AgentActivities = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [paginatedData, setPaginatedData] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tasks, setTasks] = useState([]);

  const [categoryID, setCategoryID] = useState("");
  const [taskID, setTaskID] = useState("");
  const [statusID, setStatusID] = useState("");
  const [farmerId, setFarmerId] = useState("");
  const [taskId, setTaskId] = useState("");

  // onChange function for select
  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
  };

  const setPrev = () => {
    if (page > 1) setPage(page - 1);
  };

  const setNext = () => {
    setPage(page + 1);
  };

  const status = [
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Ongoing",
      value: "ongoing",
    },
    {
      name: "Done",
      value: "done",
    },
    {
      name: "Skipped",
      value: "skipped",
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Lot ID",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <p>
              {original?.activity_farmer
                ? original?.activity_farmer?.farmer_id
                : "N/A"}
            </p>
          );
        },
      },

      {
        Header: "Agent Name",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <p>{`${original?.activity_agent?.first_name} ${original?.activity_agent?.last_name}`}</p>
          );
        },
      },
      {
        Header: "Farmer Name",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <p>
              {original?.activity_farmer
                ? `${original?.activity_farmer?.first_name} ${original?.activity_farmer?.last_name}`
                : "N/A"}
            </p>
          );
        },
      },
      {
        Header: "Date Created",
        Cell: ({ value, row }) => {
          const { original } = row;
          return <p>{moment(original?.date).format("DD-MM-YYYY")}</p>;
        },
      },
      {
        Header: "Category",
        Cell: ({ value, row }) => {
          const { original } = row;
          return <p>{original?.activity_category?.category}</p>;
        },
      },
      {
        Header: "Tasks",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <p>
              {original?.activity_task ? original?.activity_task?.task : "N/A"}
            </p>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        Cell: ({ value, row }) => {
          const { original } = row;
          return (
            <IconButton>
              <span
                onClick={() => {
                  setOpenModal(true);
                  setTaskId(original?.activity_task?.id);
                  setFarmerId(original?.activity_farmer?.id);
                }}
              >
                <ViewIcon />
              </span>
            </IconButton>
          );
        },
      },
    ],
    []
  );

  const tableData = useMemo(() => activities, [activities]);

  const getAgentActivities = useCallback(async () => {
    const params = {
      page,
      per_page: limit,
      category: categoryID,
      task_name: taskID,
      status: statusID,
    };

    if (categoryID === "") {
      delete params.category;
    }

    if (taskID === "") {
      delete params.task_name;
    }

    if (statusID === "") {
      delete params.status;
    }

    setIsLoading(true);
    try {
      const response = await axiosServices.get(`/v3/farm/activities`, {
        params,
      });
      setActivities(response?.result?.activities?.data);
      setPaginatedData(response?.result?.activities);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [categoryID, limit, page, statusID, taskID]);

  const getCategories = async () => {
    try {
      const response = await axiosServices.get("/v3/config/categories");
      setCategories(response?.result?.data);
    } catch (error) {
      return error;
    }
  };

  const getTasks = useCallback(async () => {
    const params = {
      category: categoryID,
    };
    try {
      const response = await axiosServices.get("/v3/config/activities/tasks", {
        params,
      });
      setTasks(response?.result?.data?.data);
    } catch (error) {
      return error;
    }
  }, [categoryID]);

  useEffect(() => {
    getAgentActivities();
    getCategories();
  }, [dispatch, page, limit, categoryID, taskID, statusID, getAgentActivities]);

  useEffect(() => {
    if (categoryID) {
      getTasks();
    }
  }, [categoryID, getTasks]);

  return (
    <React.Fragment>
      <section className="farm_analysis">
        <nav
          id="quick-nav"
          style={{
            backgroundColor: "white",
            padding: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <BreadCrumb
            breadcrumbs={[
              <Link href="/dashboard" key="1" color="inherit">
                Dashboard
              </Link>,
              <Typography key="2" color="primary.main">
                Agent Activities
              </Typography>,
            ]}
          />
        </nav>

        <div className="farmmanagementheader flex flex-row items-center overflow-x-auto">
          <h1
            className="filtertext ml-5 mt-2"
            style={{
              marginTop: "8px !important",
              marginBottom: "8px !important",
            }}
          >
            Filter by:
          </h1>

          <div className="ml-5 my-1 flex-grow">
            <CustomSelect
              height="32px"
              width="100%"
              noneLabel={<em>Category</em>}
              borderRadius="5px"
              backgroundColor="#FFFFFF"
              onChange={(e) => setCategoryID(e.target.value)}
              options={
                categories &&
                categories.map((category) => {
                  return {
                    name: category.category,
                    value: category.id,
                  };
                })
              }
            />
          </div>

          <div className="ml-5 my-1 flex-grow">
            <CustomSelect
              height="32px"
              width="100%"
              noneLabel={<em>Tasks</em>}
              options={
                tasks &&
                tasks.map((task) => {
                  return {
                    name: task.task,
                    value: task.id,
                  };
                })
              }
              sx={{ borderRadius: "5px" }}
              backgroundColor="#FFFFFF"
              onChange={(e) => setTaskID(e.target.value)}
            />
          </div>

          <div className="ml-5 my-1 flex-grow">
            <CustomSelect
              height="32px"
              width="100%"
              noneLabel={<em>Status</em>}
              options={
                status &&
                status.map((item) => {
                  return {
                    name: item.name,
                    value: item.value,
                  };
                })
              }
              borderRadius="5px"
              backgroundColor="#FFFFFF"
              onChange={(e) => setStatusID(e.target.value)}
            />
          </div>

          {/* <div className='ml-5 mt-2 flex-grow'>
              <CustomSelect
                height='32px'
                width='100%'
                noneLabel={<em>Sub Cluster</em>}
                // options={
                //   subClusters &&
                //   subClusters.map((eachCluster) => {
                //     return {
                //       name: eachCluster.name,
                //       value: eachCluster.sub_cluster_id,
                //     };
                //   })
                // }
                borderRadius='5px'
                backgroundColor='#FFFFFF'
                onChange={(e) => console.log(e.target.value)}
              />
            </div> */}
        </div>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <h2
            className="with-spinner whitespace-nowrap"
            style={{ fontSize: "20px" }}
          >
            <span>Agent Activities</span>
            {/* {isLoading ? <Spinner size={20} color="primary" /> : null} */}
          </h2>

          {/* <TableCustomPagination
            first_page={1}
            present_page={paginatedData?.current_page || 1}
            last_page={paginatedData?.last_page}
            click={(page) => setPage(page)}
            pageSize={limit}
            setPageSize={setLimit}
            totalRows={paginatedData?.total || 0}
          /> */}

          <Box sx={{ p: 0 }}>
            <TableActions
              showLimit={false}
              nextHandler={() => setNext()}
              prevHandler={() => setPrev()}
              limitHandler={(e) => handleLimitChange(e)}
              totalRecords={paginatedData?.total}
              limit={limit}
              page={page}
            />
          </Box>
        </Stack>

        <Stack
          flexGrow={1}
          sx={{
            borderRadius: "5px",
            backgroundColor: "white",
            pb: 2,
          }}
        >
          {/* <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems={{ md: "center", xs: "flex-start" }}
            justifyContent={"space-between"}
            flexGrow={1}
            className="mb-2"
          ></Stack> */}

          <Table columns={columns} data={tableData} isLoading={isLoading} />
        </Stack>

        {openModal && (
          <ActivityCard
            setShowActivityModal={setOpenModal}
            taskId={taskId}
            farmerId={farmerId}
          />
        )}
      </section>
    </React.Fragment>
  );
};

export default AgentActivities;
