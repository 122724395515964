import { createSlice } from "@reduxjs/toolkit";
import {
  getInputCategories,
  getInputs,
  getInputsDistribution,
  getNewInputs,
} from "../actions/input.actions";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  inputs: [],
  total: 0,
  loading: false,
  associations: [],
  loadingAssociations: false,
  inputCategory: [],
  newInputs: [],
  newInputsDistribution: [],
};

const inputTrackingSlice = createSlice({
  name: "input",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInputs.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.loading = false;
        state.inputs = payload?.data || [];
        state.total = payload?.total || 0;
      })
      .addCase(getInputs.pending, (state, action) => {
        // console.log("pending");
        state.loading = true;
      })
      .addCase(getInputs.rejected, (state, { error }) => {
        // console.log("rejected");
        state.loading = false;
        // notify(error.message, { type: "error" });
      })

      .addCase(getInputCategories.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.loading = false;
        state.inputCategory = payload || [];
      })
      .addCase(getInputCategories.pending, (state, action) => {
        // console.log("pending");
        state.loading = true;
      })
      .addCase(getInputCategories.rejected, (state, { error }) => {
        // console.log("rejected");
        state.loading = false;
        // notify(error.message, { type: "error" });
      })

      // builder
      .addCase(getNewInputs.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        // console.log("payload actions", payload);
        state.loading = false;
        state.newInputs = payload || [];
      })
      .addCase(getNewInputs.pending, (state, action) => {
        // console.log("pending");
        state.loading = true;
      })
      .addCase(getNewInputs.rejected, (state, { error }) => {
        // console.log("rejected");
        state.loading = false;
        // notify(error.message, { type: "error" });
      })

      // builder
      .addCase(getInputsDistribution.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.loading = false;
        state.newInputsDistribution = payload || [];
      })
      .addCase(getInputsDistribution.pending, (state, action) => {
        // console.log("pending");
        state.loading = true;
      })
      .addCase(getInputsDistribution.rejected, (state, { error }) => {
        // console.log("rejected");
        state.loading = false;
        // notify(error.message, { type: "error" });
      });
  },
});

export default inputTrackingSlice;
