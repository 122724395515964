import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { MdLogout } from "react-icons/md";

import {
  CssBaseline,
  Toolbar,
  AppBar,
  Button as MuiButton,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";

import { CgMenuLeftAlt as MenuIcon } from "react-icons/cg";
import UserAvatar from "./common/UserAvatar";
// import axiosServices from "../services/axiosServices";
import axiosServicesCropGate from "../services/cropgateAxiosService";
import cropgateAxiosService from "../services/cropgateAxiosService";
// import { BASE_URL } from "../configs/api";
import { theme } from "../context/ThemeProvider";
import { clearStorage, getAuthUser } from "../utils/storage";
// import { formatAsMoney, numberWithCommas } from "../utils/helpers";
import ToggleSwitch from "./ToggleSwitch";
// import { logout } from "../redux/slices/Auth.slice";

function Header(props) {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [, setLoading] = useState(false);
  const [, setDashboardStats] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [updatedStats, setUpdatedStats] = useState({});

  const authenticatedUser = useSelector(
    (state) => state?.Auth?.userProfile || getAuthUser()
  );

  const userInfo = JSON.parse(localStorage.getItem("user_profile"));
  // console.log("test", userInfo?.roles?.length === 2);

  const getDashboardStats = async () => {
    try {
      setLoading(true);
      const res = await axiosServicesCropGate.get(`/v1/dashboard/stats`);
      const res2 = await axiosServicesCropGate.get("/v1/payments");
      const res3 = await cropgateAxiosService.get(`/v1/dashboard/metrics`);
      // console.log("res", res?.result?.stats);
      setDashboardStats(res?.result?.stats);
      setAccounts(res2?.result?.accounts);
      setUpdatedStats(res3?.result?.stats);
      // console.log("res2", res2);
      //console.log("res3", res3);
      setLoading(false);
    } catch (error) {
      // console.log("error", error?.response?.data?.message);
      setLoading(false);
    }
  };

  // console.log("accounts", accounts);

  useEffect(() => {
    getDashboardStats();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const toggleProfileMenu = (show) => (event) => {
    if (show) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleLogout = (event) => {
    // dispatch(logout(navigate));
    clearStorage();
    toggleProfileMenu(false)(event);
  };

  const isSuperTenant = localStorage.getItem("isSuperTenant");

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative" elevation={0} color="secondary">
        <Toolbar
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div>
            <MuiButton
              sx={{ padding: "10px 10px 10px 20px" }}
              id="header-profile-button"
              aria-controls={open ? "header-profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={toggleProfileMenu(true)}
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  overflow: "hidden",
                }}
              >
                <UserAvatar
                  size="32px"
                  fontSize="13px"
                  firstname={authenticatedUser?.first_name || ""}
                  lastname={authenticatedUser?.last_name || ""}
                />
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{ ml: "13px", textTransform: "capitalize" }}
                >
                  <h3
                    style={{ margin: "0", fontWeight: "800", fontSize: "14px" }}
                  >
                    {`${authenticatedUser?.first_name || "-"} ${
                      authenticatedUser?.last_name || "-"
                    }`}
                  </h3>
                  <span style={{ fontSize: "12px" }}>
                    {/* {authenticatedUser?.myRole?.role || "-"} */}
                    {isSuperTenant === "ON"
                        ? "Super Tenant"
                        : authenticatedUser?.myRole?.role || "-"}
                  </span>
                </Stack>
              </Stack>
            </MuiButton>

            <Menu
              id="header-profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={toggleProfileMenu(false)}
              MenuListProps={{
                "aria-labelledby": "header-profile-button",
              }}
              sx={{
                "& .MuiMenu-paper": {
                  borderRadius: "15px",
                  boxShadow: "0px 5px 20px rgba(108, 117, 125, 0.4)",
                  width: "350px",
                  maxWidth: "100%",
                  // smaller than small
                  "@media (max-width: 600px)": {
                    width: "100%",
                    // borderRadius: "0",
                    // boxShadow: "none",
                  },
                },
              }}
            >
              {/* <MenuItem onClick={toggleProfileMenu(false)}>
                <MuiButton
                  href="/profile"
                  sx={{
                    p: 0,
                    m: 0,
                    textTransform: "capitalize",
                    color: "inherit",
                    fontSize: "inherit",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Edit Profile
                </MuiButton>
              </MenuItem> */}

              <MenuItem sx={styles.menuItem}>
                <Stack
                  direction="column"
                  alignItems="center"
                  sx={{
                    overflow: "hidden",
                  }}
                  // marginTop={"30px"}
                  // marginLeft={"60px"}
                  // marginRight={"60px"}
                  // marginBottom={"50px"}
                >
                  <UserAvatar
                    size="35px"
                    fontSize="20px"
                    firstname={authenticatedUser?.first_name || ""}
                    lastname={authenticatedUser?.last_name || ""}
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    sx={{ textTransform: "capitalize" }}
                  >
                    <h3
                      style={{
                        marginTop: "10px",
                        fontWeight: "800",
                        fontSize: "14px",
                      }}
                    >
                      {`${authenticatedUser?.first_name || "-"} ${
                        authenticatedUser?.last_name || "-"
                      }`}
                    </h3>
                    <h3 style={{ fontSize: "12px", textAlign: "center" }}>
                      {isSuperTenant === "ON"
                        ? "Super Tenant"
                        : authenticatedUser?.myRole?.role || "-"}
                    </h3>
                  </Stack>

                  {userInfo?.roles?.length === 2 && (
                    <ToggleSwitch
                      labelStyle={{
                        color: "blue",
                        fontWeight: "bold",
                        fontSize: "12px",
                        fontFamily: "Helvetica",
                      }}
                      switchColor="secondary"
                    />
                  )}

                  <Stack
                    direction="column"
                    alignItems="center"
                    sx={styles.backgroundColor}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid
                          item
                          xs={5}
                          // marginTop={"5px"}
                          // marginBottom={"10px"}
                          // textAlign={"left"}
                        >
                          <p className="info_text text-[12px]">Business name</p>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          // marginTop={"5px"}
                          // marginBottom={"10px"}
                          textAlign={"right"}
                        >
                          <p style={{ fontSize: "12px", fontWeight: "700" }}>
                            {userInfo?.tenant_profile?.name ||
                              userInfo?.tenant?.name}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          // marginTop={"10px"}
                          // marginBottom={"10px"}
                          // textAlign={"center"}
                        >
                          <p className="info_text text-[12px]">
                            Profile Status
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          // marginTop={"10px"}
                          // marginBottom={"10px"}
                          textAlign={"right"}
                        >
                          <p style={{ fontSize: "12px", fontWeight: "700" }}>
                            {/* {userInfo?.status || "--"} */}
                            {accounts?.length > 0
                              ? "Completed"
                              : "Not Completed"}
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          // marginTop={"10px"}
                          // marginBottom={"10px"}
                          // textAlign={"center"}
                        >
                          <p className="info_text text-[12px]">Transactions</p>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          // marginTop={"10px"}
                          // marginBottom={"10px"}
                          textAlign={"right"}
                        >
                          <p style={{ fontSize: "12px", fontWeight: "700" }}>
                            {/* {dashboardStats?.total_transactions || "--"} */}
                            {updatedStats?.number_of_transactions || "--"}
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>

                  <Stack sx={styles.logoutIcon}>
                    <span onClick={handleLogout}>
                      <IconButton style={{ color: "#da1513", float: "left" }}>
                        <MdLogout />
                      </IconButton>
                      <p
                        style={{
                          color: "#da1513",
                          fontSize: "16px",
                          fontWeight: "600",
                          paddingTop: "10px",
                          alignItems: "center",
                        }}
                      >
                        Logout
                      </p>
                    </span>
                  </Stack>
                </Stack>
              </MenuItem>

              {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
            </Menu>
          </div>
          <IconContext.Provider
            value={{ size: "28px", color: `${theme.palette.primary.main}` }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.toggler}
              sx={{ ml: -1, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </IconContext.Provider>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

const styles = {
  backgroundColor: {
    backgroundColor: theme.palette.primary.main,
    backgroundColor: "#F7F7FC",
    borderRadius: "8px",
    pt: 2,
    pb: 2,
    px: 1,
    mx: 1,
    // my: 4,
    minWidth: "185px",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  logoutIcon: {
    backgroundcolor: "#F7F7FC",
  },
};

export default Header;
