import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  // Redirect
} from "react-router-dom";

import { ToastContainer as DefaultToastContainer } from "react-toastify";

// Import Contexts
import { theme, ThemeProvider } from "./context/ThemeProvider";
import SuspenseFallback from "./components/SuspenseFallback";
import ErrorBoundary from "./components/ErrorBoundary";
import NoPage from "./components/UnknownPage";

// Other assets
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";

// Import Pages
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";

import AgentActivities from "./layouts/Dashboard/agentActivities/AgentActivities";

const EditNewUser = React.lazy(() =>
  import("./layouts/UserManagement/EditNewUser")
);

const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const RegEmailSent = React.lazy(() => import("./pages/RegEmailSent"));
const CreatePassword = React.lazy(() => import("./pages/CreatePassword"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndCondition")
);

const AddFarmer = React.lazy(() =>
  import("./layouts/SupplierManagement/AddFarmer")
);

const DashboardIndexSection = React.lazy(() =>
  import("./layouts/Dashboard/Index")
);

const FarmActivities = React.lazy(() =>
  import("./layouts/Dashboard/FarmActivities")
);

const CompleteCompanyProfile = React.lazy(() =>
  import("./layouts/Dashboard/CompleteCompanyProfile")
);

const SelectAccountType = React.lazy(() =>
  import("./layouts/Dashboard/SelectAccount")
);

const SoleProprietorship = React.lazy(() =>
  import("./layouts/Dashboard/SoleProprietorship")
);

const LimitedLiabilityCompany = React.lazy(() =>
  import("./layouts/Dashboard/LimitedLiabilityCompany")
);

const ReferralPartnerDashboard = React.lazy(() =>
  import("./layouts/Dashboard/DashboardReferralPartners")
);

const ReferralEarnings = React.lazy(() =>
  import("./layouts/ReferralEarnings/EarningsIndex")
);

const AddNewAccount = React.lazy(() =>
  import("./layouts/ReferralEarnings/AddAccount")
);

const SuperAdmin = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin")
);

const SourcingAgents = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageSourcingAgents")
);

const AddSourcingAgent = React.lazy(() =>
  import(
    "./layouts/UserManagement/SuperAdmin/ManageSourcingAgents/AddSourcingAgent"
  )
);

const EditTenants = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageTenants/EditTenants")
);

const AddTenants = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageTenants/AddTenants")
);

const ViewMyTenants = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageSuperTenant/ViewMyTenants")
);

const AssignTenants = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageSuperTenant/AssignTenants")
);

const Roles = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageRoles/Roles")
);

const EditRoles = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageRoles/EditRoles")
);

const AddRoles = React.lazy(() =>
  import("./layouts/UserManagement/SuperAdmin/ManageRoles/AddRoles")
);

const CropGateUsers = React.lazy(() =>
  import("./layouts/UserManagement/Users")
);

const CropGateReferalPartners = React.lazy(() =>
  import("./layouts/Referral/ReferralPartners")
);

const CropGateSuppliers = React.lazy(() =>
  import("./layouts/SupplierManagement/Suppliers")
);

const FarmersManagement = React.lazy(() =>
  import("./layouts/FarmerManagement/Farmers")
);

const FarmersImportedHistory = React.lazy(() =>
  import("./layouts/FarmerManagement/FarmersImportedHistory")
);

const CropGateViewSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/ViewSupplier")
);

const CropGateViewFarmer = React.lazy(() =>
  import("./layouts/SupplierManagement/ViewFarmer1")
);

const ViewFarmer = React.lazy(() =>
  import("./layouts/FarmerManagement/ViewFarmer")
);

const CropGateEditFarmer = React.lazy(() =>
  import("./layouts/SupplierManagement/EditFarmer")
);

const EditFarmer = React.lazy(() =>
  import("./layouts/FarmerManagement/EditFarmer")
);

const CropGateAddSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/AddSupplier")
);

const CropGateEditSupplier = React.lazy(() =>
  import("./layouts/SupplierManagement/EditSupplier")
);

const CropGateSupplies = React.lazy(() =>
  import("./layouts/Collections/Supplies")
);

const CropGateViewSupplies = React.lazy(() =>
  import("./layouts/Collections/ViewSupplies")
);

const CropGatePayments = React.lazy(() =>
  import("./layouts/PaymentAccount/Index")
);

const CropGateViewPaymentSupplies = React.lazy(() =>
  import("./layouts/PaymentAccount/ViewSuppliesDetails")
);

const AddNewUser = React.lazy(() =>
  import("./layouts/UserManagement/AddNewUser")
);

const AddNewTenant = React.lazy(() => import("./layouts/Tenants/AddNewTenant"));

const AddReferralPartner = React.lazy(() =>
  import("./layouts/Referral/AddReferralPartner")
);

const UserDetails = React.lazy(() =>
  import("./layouts/UserManagement/UserDetails")
);

const SuperTenantUserDetails = React.lazy(() =>
  import("./layouts/UserManagement/SuperTenantUserDetails")
);

const CashAdvance = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvance")
);

const CashAdvanceSupplierDetails = React.lazy(() =>
  import("./layouts/CashAdvance/CashAdvanceSupplierDetails")
);

const Messages = React.lazy(() => import("./layouts/Communication/Messages"));

const EditInformation = React.lazy(() => import("./layouts/Settings/Index"));

const AddNewPin = React.lazy(() => import("./layouts/Settings/AddNewPin"));

const AddNewPassword = React.lazy(() =>
  import("./layouts/Settings/AddNewPassword")
);

// CONFIGURATIONS
const CropProfileSection = React.lazy(() =>
  import("./layouts/Configurations/CropProfiles")
);

const ViewCropProfileSection = React.lazy(() =>
  import("./layouts/Configurations/ViewCropProfile")
);

const CropProfileDraftSection = React.lazy(() =>
  import("./layouts/Configurations/CropProfileDrafts")
);

const AddCropProfile = React.lazy(() =>
  import("./layouts/Configurations/AddCropProfile")
);

const SetDefaultTask = React.lazy(() =>
  import("./layouts/Configurations/SetDefaultTask")
);

const EditCropProfile = React.lazy(() =>
  import("./layouts/Configurations/EditCropProfile")
);

// const ManageCropCategoriesSection = React.lazy(() =>
//   import("./layouts/Configurations/ManageCategories")
// );

const ViewCropCategory = React.lazy(() =>
  import("./layouts/Configurations/ViewCategory")
);

const AddCategory = React.lazy(() =>
  import("./layouts/Configurations/AddCategory")
);

const EditCategory = React.lazy(() =>
  import("./layouts/Configurations/EditCategory")
);

const FarmSeasonSection = React.lazy(() =>
  import("./layouts/Configurations/FarmSeasons")
);

const ViewSeason = React.lazy(() =>
  import("./layouts/Configurations/ViewSeason")
);

const AddSeason = React.lazy(() =>
  import("./layouts/Configurations/AddSeason")
);

const StartSeason = React.lazy(() =>
  import("./layouts/Configurations/StartSeason")
);

const FarmManagement = React.lazy(() =>
  import("./layouts/FarmManagement/Index")
);

const FarmLots = React.lazy(() =>
  import("./layouts/FarmManagement/ManageFarmLots")
);

const ViewFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/ViewFarmUnit")
);

const EditFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/EditFarmUnit")
);

const AddFarmUnit = React.lazy(() =>
  import("./layouts/FarmManagement/AddFarmUnit")
);

const FarmClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ManageClusters")
);

const ViewFarmClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ViewCluster")
);

const FarmSubClusters = React.lazy(() =>
  import("./layouts/FarmManagement/ManageSubClusters")
);

const ViewSubclusterLots = React.lazy(() =>
  import("./layouts/FarmManagement/ViewSubCluster")
);

const InputTracking = React.lazy(() => import("./layouts/InputTracking/Input"));

const AddInput = React.lazy(() => import("./layouts/InputTracking/AddInput"));

// create a default container so we can override the styles
const ToastContainer = (props) => (
  <DefaultToastContainer style={{ zIndex: "1900" }} {...props} />
);

function App() {
  useEffect(() => {
    const hostname = window.location.hostname;
    let title;
    let faviconPath = "/favicon.ico"; // Default favicon path

    if (hostname.includes("https://agro-new.tradebuza.com/")) {
      title = "Agro Cropgate";
      faviconPath = "/favicon.ico";
    } else if (hostname.includes("app.getyieldpro.com")) {
      title = "Yieldpro by AgroEknor";
      faviconPath = "/special-favicon.ico";
    } else if (hostname.includes("app.projectgrow.ng")) {
      title = "ProjectGrow";
      faviconPath = "/project-grow-favicon.ico";
    } else if (hostname.includes("localhost")) {
      title = "Agro localhost";
      faviconPath = "/favicon.ico";
    } else {
      title = "Agro";
    }

    document.title = title;
    const link =
      document.querySelector('link[rel="icon"]') ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = faviconPath;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          <Router>
            <Switch>
              <Route exact path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/email_sent" element={<RegEmailSent />} />
              <Route
                path="/confirm/email/:id/:id"
                element={<CreatePassword />}
              />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route
                path="/forgot/password/:id/:id"
                element={<ResetPassword />}
              />

              {/* DASHBOARD */}
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<DashboardIndexSection />} />
                <Route path="overview" element={<DashboardIndexSection />} />
                <Route path="select-account" element={<SelectAccountType />} />
                <Route
                  path="complete-company-profile"
                  element={<CompleteCompanyProfile />}
                />

                <Route
                  path="complete-company-profile/sole-proprietorship"
                  element={<SoleProprietorship />}
                />

                <Route
                  path="complete-company-profile/limited-liability-company"
                  element={<LimitedLiabilityCompany />}
                />
                {/* <Route path="farm-analysis" element={<FarmAnalysis />} /> */}
                <Route path="farm-activities" element={<FarmActivities />} />
                <Route path="agent-activities" element={<AgentActivities />} />
              </Route>

              {/* SUPPLIER MANAGEMENT */}
              <Route
                path="/supplier-management"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<CropGateSuppliers />} />
                <Route path="add-supplier" element={<CropGateAddSupplier />} />
                <Route path="add-farmer" element={<AddFarmer />} />
                <Route
                  path="update-supplier/:supplierId"
                  element={<CropGateEditSupplier />}
                />
                <Route
                  path="suppliers/:supplierId"
                  element={<CropGateViewSupplier />}
                />
                <Route
                  path="farmers/:farmerId"
                  element={<CropGateViewFarmer />}
                />
                <Route
                  path="update-farmer/:farmerId"
                  element={<CropGateEditFarmer />}
                />
              </Route>

              {/* FARMER MANAGEMENT */}
              <Route
                path="/farmer-management"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<FarmersManagement />} />
                <Route path="add-farmer" element={<AddFarmer />} />
                {/* <Route
                  path="update-supplier/:supplierId"
                  element={<CropGateEditSupplier />}
                />
                <Route
                  path="suppliers/:supplierId"
                  element={<CropGateViewSupplier />}
                /> */}
                <Route path="farmers/:farmerId" element={<ViewFarmer />} />
                <Route path="import-history" element={<FarmersImportedHistory />} />
                <Route
                  path="update-farmer/:farmerId"
                  element={<EditFarmer />}
                />
              </Route>

              {/* CONFIGURATIONS */}
              <Route path="/configurations" element={<Dashboard />}>
                <Route index element={<DashboardIndexSection />} />
                <Route path="crop-profile" element={<CropProfileSection />} />
                <Route
                  path="crop-profile/drafts"
                  element={<CropProfileDraftSection />}
                />
                <Route
                  path="crop-profile/:cropId"
                  element={<ViewCropProfileSection />}
                />
                <Route
                  path="crop-profiles/add-crop"
                  element={<AddCropProfile />}
                />
                <Route
                  path="crop-profiles/edit-crop/:cropId"
                  element={<EditCropProfile />}
                />
                <Route
                  path="crop-profiles/set-default-task/:cropId"
                  element={<SetDefaultTask />}
                />
                <Route
                  path="crop-profile/:cropId/add-category"
                  element={<AddCategory />}
                />
                <Route
                  path="crop-profile/:cropId/edit-category/:categoryId"
                  element={<EditCategory />}
                />
                <Route
                  path="crop-profile/:cropId/category/:categoryId"
                  element={<ViewCropCategory />}
                />
                {/* <Route
                  path="crop-calendar/manage-categories"
                  element={<ManageCropCategoriesSection />}
                /> */}

                <Route path="farm-seasons" element={<FarmSeasonSection />} />
                <Route
                  path="farm-seasons/:seasonDate"
                  element={<ViewSeason />}
                />
                <Route
                  path="farm-season/add-new-season"
                  element={<AddSeason />}
                />
                <Route
                  path="farm-season/start-new-season"
                  element={<StartSeason />}
                />
              </Route>

              {/* FARM MANAGEMENT */}
              <Route path="/farm-management" element={<Dashboard />}>
                <Route index element={<FarmManagement />} />
                <Route path="farm-lots" element={<FarmLots />} />
                <Route path="add-farm-unit" element={<AddFarmUnit />} />
                <Route
                  path="edit-farm-unit/:farmId"
                  element={<EditFarmUnit />}
                />
                <Route
                  path="view-farm-unit/:farmId"
                  element={<ViewFarmUnit />}
                />
                <Route path="clusters" element={<FarmClusters />} />
                <Route
                  path="view-farm-unit/:farmId"
                  element={<ViewFarmUnit />}
                />
                <Route path="clusters" element={<FarmClusters />} />
                <Route
                  path="clusters/:clusterId"
                  element={<ViewFarmClusters />}
                />
                <Route path="sub-clusters" element={<FarmSubClusters />} />
                <Route
                  path="sub-clusters/:subClusterId"
                  element={<ViewSubclusterLots />}
                />
              </Route>

              {/* INPUT TRACKING */}
              <Route path="/input-tracking" element={<Dashboard />}>
                <Route index element={<InputTracking />} />
                <Route path="add-input" element={<AddInput />} />
              </Route>

              {/* PAYMENT & ACCOUNTS */}
              <Route
                path="/payment-account"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<CropGatePayments />} />
                <Route
                  path="supplies/:supplyId"
                  element={<CropGateViewPaymentSupplies />}
                />
              </Route>

              {/* REFERRALS */}
              <Route
                path="/referrals"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route
                  path="dashboard"
                  element={<ReferralPartnerDashboard />}
                />
                <Route path="earnings" element={<ReferralEarnings />} />
                <Route path="new-account" element={<AddNewAccount />} />
              </Route>

              {/* USER MANAGEMENT */}
              <Route
                path="/user-management"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<SuperAdmin />} />
                <Route path="edit-tenant/:id" element={<EditTenants />} />
                <Route path="add-tenant" element={<AddTenants />} />
                <Route path="roles" element={<Roles />} />
                <Route
                  path="super-tenant/view-tenants"
                  element={<ViewMyTenants />}
                />
                <Route
                  path="super-tenant/view-tenants/:id/assign-tenant"
                  element={<AssignTenants />}
                />
                <Route path="edit-role/:id" element={<EditRoles />} />
                <Route path="roles/add-role" element={<AddRoles />} />

                <Route path="sourcing-agents" element={<SourcingAgents />} />
                <Route
                  path="sourcing-agents/add-sourcing-agent"
                  element={<AddSourcingAgent />}
                />
              </Route>

              {/* CROP GATE */}
              <Route
                path="/crop-gate"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                <Route index element={<DashboardIndexSection />} />
                <Route path="settings" element={<EditInformation />} />
                <Route path="settings/add-pin" element={<AddNewPin />} />
                <Route
                  path="settings/add-password"
                  element={<AddNewPassword />}
                />
                <Route
                  path="user-management/users"
                  element={<CropGateUsers />}
                />
                <Route
                  path="referral-partners"
                  element={<CropGateReferalPartners />}
                />
                <Route
                  path="referral-partners/add-referral-partner"
                  element={<AddReferralPartner />}
                />
                <Route
                  path="user-management/add-user"
                  element={<AddNewUser />}
                />
                <Route
                  path="user-management/edit-user/:id"
                  element={<EditNewUser />}
                />
                <Route
                  path="user-management/add-tenant"
                  element={<AddNewTenant />}
                />
                <Route
                  path="user-management/user/:userId"
                  element={<UserDetails />}
                />
                <Route
                  path="user-management/super-tenant/:id"
                  element={<SuperTenantUserDetails />}
                />
                <Route path="collections" element={<CropGateSupplies />} />
                <Route
                  path="collections/supplies/:supplyId"
                  element={<CropGateViewSupplies />}
                />
                <Route path="cash-advance" element={<CashAdvance />} />
                <Route
                  path="cash-advance/supplier/:id"
                  element={<CashAdvanceSupplierDetails />}
                />
                <Route path="communication" element={<Messages />} />
              </Route>

              <Route path="*" element={<NoPage />} />
            </Switch>
          </Router>
          <ToastContainer />
        </React.Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
