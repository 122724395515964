import axios from "axios";
import { PAYMENT_BASE_URL } from "../configs/api";

import { setMiddleWares } from "./index";

// axios.defaults.withCredentials = true
// Add a request interceptor
const createConfig = {
  BASE_URL: PAYMENT_BASE_URL,
};

const axiosServices = axios.create(createConfig);

const _getAxiosService = (contentType = "json") => {
  // console.log(contentType);
  setMiddleWares(axiosServices, contentType, PAYMENT_BASE_URL);
  return axiosServices;
};

_getAxiosService();

export default axiosServices;

export const useAxiosService = _getAxiosService;
