import {
  getSupplies,
  getAttendedToSupplies,
  getNotAttendedToSupplies,
} from "../actions/collections.action";
import { createSlice } from "@reduxjs/toolkit";
// import { notify } from "../../utils/toastNotification";

const initialState = {
  supplies: [],
  isLoading: false,
  total: 0,
};

const collectionSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSupplies.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoading = false;
        state.supplies = payload?.supplies?.data || [];
        state.total = payload?.supplies?.total || 0;
      })
      .addCase(getSupplies.pending, (state, action) => {
        // console.log("pending");
        state.isLoading = true;
      })
      .addCase(getSupplies.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getAttendedToSupplies.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoading = false;
        state.supplies = payload?.supplies?.data || [];
        state.total = payload?.supplies?.total || 0;
      })
      .addCase(getAttendedToSupplies.pending, (state, action) => {
        // console.log("pending");
        state.isLoading = true;
      })
      .addCase(getAttendedToSupplies.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      })
      .addCase(getNotAttendedToSupplies.fulfilled, (state, { payload }) => {
        // console.log("fulfilled");
        state.isLoading = false;
        state.supplies = payload?.supplies?.data || [];
        state.total = payload?.supplies?.total || 0;
      })
      .addCase(getNotAttendedToSupplies.pending, (state, action) => {
        // console.log("pending");
        state.isLoading = true;
      })
      .addCase(getNotAttendedToSupplies.rejected, (state, { error }) => {
        // console.log("rejected");
        state.isLoading = false;
        // notify(error.message, { type: "error" });
      });
  },
});

export default collectionSlice;
