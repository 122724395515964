import axiosServices, { useAxiosService } from "../../services/axiosServices";
import { notify } from "../../utils/toastNotification";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";

import { convertToFormData } from "../../utils/convertJSONToFormData";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getClusters = createAsyncThunk(
  "farm-management/getClusters",
  async (queryParams = {}, thunkAPI) => {
    try {
      const response = await axiosServices.get("/v3/clusters");
      // console.log(cropProfiles);
      const success = response.success;
      const data = response.result?.clusters;

      // if (success && data) {
      //   dispatch({
      //     type: GET_CLUSTERS_SUCCESS,
      //     payload: data,
      //   });
      // }
      return data;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleCluster = async (clusterId) => {
  try {
    const res = await axiosServices.get(`/v3/clusters/${clusterId}`);
    return res?.result?.cluster;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addCluster = createAsyncThunk(
  "farm-management/addCluster",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v3/clusters", data);
      console.log(response);
      if (response) {
        notify("Cluster created", { type: "success" });
        thunkAPI.dispatch(getClusters({}));
      } else {
        notify(response.message || "An error occured", { type: "error" });
      }
      return response;
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to add cluster");
      throw error;
    }
  }
);

export const updateCluster = createAsyncThunk(
  "farm-management/updateCluster",
  async (clusterId, data, thunkAPI) => {
    try {
      const response = await axiosServices.patch(
        `/v3/clusters/${clusterId}`,
        data
      );
      console.log(response);
      if (response) {
        notify("Cluster updated", { type: "success" });
        // dispatch(getClusters({}));
      } else {
        notify(response.message || "An error occured", { type: "error" });
      }
      return response;
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to update cluster");
      throw error;
    }
  }
);

export const addSubCluster = (data) => async (dispatch) => {
  try {
    const response = await axiosServices.post("/v3/sub-clusters", data);
    console.log(response);
    if (response) {
      notify("Sub-Cluster created", { type: "success" });
      dispatch(getSubClusters({}));
    } else {
      notify(response.message || "An error occured", { type: "error" });
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to add subcluster");
    return error;
  }
};

export const updateSubcluster = (subClusterId, data) => async (dispatch) => {
  try {
    const response = await axiosServices.patch(
      `/v3/sub-clusters/${subClusterId}`,
      data
    );
    console.log(response);
    if (response) {
      notify("Sub-cluster updated", { type: "success" });
      dispatch(getSubClusters({}));
    } else {
      notify(response.message || "An error occured", { type: "error" });
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to update sub-cluster");
    return error;
  }
};

export const getSubClusters = createAsyncThunk(
  "farm-management/getSubClusters",
  async (queryParams = {}, thunkAPI) => {
    try {
      // dispatch({
      //   type: GET_SUB_CLUSTERS,
      // });

      const queryString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v3/sub-clusters${queryString}`
      );
      // console.log(response);
      // const success = response.success;
      // const data = response?.result["sub-clusters"];

      // if (success && data) {
      //   dispatch({
      //     type: GET_SUB_CLUSTERS_SUCCESS,
      //     payload: data,
      //   });
      // } else {
      //   dispatch({
      //     type: GET_SUB_CLUSTERS_FAIL,
      //   });
      //   notify(response.message || "An error occured", { type: "error" });
      // }

      return response?.result["sub-clusters"];
    } catch (error) {
      // console.log(error);
      // dispatch({
      //   type: GET_SUB_CLUSTERS_FAIL,
      // });
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleSubCluster = async (subclusterId) => {
  try {
    const res = await axiosServices.get(`/v3/sub-clusters/${subclusterId}`);
    return res?.result["sub-cluster"];
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getFarmLots = createAsyncThunk(
  "farm-management/getFarmLots",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      console.log(queryString);
      const response = await axiosServices.get(`/v3/farms${queryString}`);
      // console.log(response);
      // const success = response.success;
      // const data = response.result?.data?.data;

      // if (success && data) {
      //   dispatch({
      //     type: GET_FARM_LOTS_SUCCESS,
      //     payload: {
      //       data,
      //       total: response.result.data.total,
      //     },
      //   });
      // } else {
      //   dispatch({
      //     type: GET_FARM_LOTS_FAIL,
      //   });
      //   notify(response.message || "An error occured", { type: "error" });
      // }

      return response.result?.data;
    } catch (error) {
      // console.log(error);
      // dispatch({
      //   type: GET_FARM_LOTS_FAIL,
      // });
      errorHandler(error);
      throw error;
    }
  }
);

export const addFarmUnit = createAsyncThunk(
  "farm-management/getFarmLots",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v3/farms", data);

      if (response) {
        thunkAPI.dispatch(getFarmLots({}));
        notify("Farm unit created", { type: "success" });
        return response;
      }

      notify(response.message || "An error occured", { type: "error" });
      return response
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to add farm unit");
      throw error;
    }
  }
);

export const updateFarmUnit = (farmId, data) => async (dispatch) => {
  try {
    const response = await axiosServices.patch(`/v3/farms/${farmId}`, data);
    console.log(response);
    if (response) {
      notify("Farm updated", { type: "success" });
      dispatch(getFarmLots({}));
    } else {
      notify(response.message || "An error occured", { type: "error" });
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to update farm");
    return error;
  }
};

export const bulkAssignFarms = (data) => async (dispatch) => {
  try {
    const response = await axiosServices.patch(`/v3/farms/bulk/assign`, data);
    // console.log(response);
    if (response?.success) {
      notify("Farm assigned", { type: "success" });
      dispatch(getFarmLots({ page: 1 }));
    } else {
      notify(response.message || "An error occured", { type: "error" });
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to assign farms");
    return error;
  }
};

export const bulkUploadFarms = (data) => async (dispatch) => {
  try {
    data = convertToFormData(data);
    const res = await useAxiosService("formData")({
      method: "post",
      url: `/v3/farm/bulk-upload`,
      data,
    });

    if (res?.success && res?.result) {
      notify("Farms uploaded successfully", { type: "success" });
      dispatch(getFarmLots({ page: 1 }));
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to upload farms");
    return error;
  }
};
