import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosServices from "../../services/axiosServices";
import { notify } from "../../utils/toastNotification";
import { errorHandler } from "../../utils/actionsErrorHandler";
import { createQuery } from "../../utils/createQuery";

export const addCropProfile = createAsyncThunk(
  "cropProfiles/addCropProfile",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post(
        "/v4/config/crop-profile",
        data
      );

      if (response?.success) {
        notify(
          !data?.draft
            ? "Crop profile created"
            : "Crop profile saved successfully",
          { type: "success" }
        );
        thunkAPI.dispatch(getCropProfiles());
      } else {
        notify("Failed", { type: "error" });
      }

      return response;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const updateCropProfile = createAsyncThunk(
  "cropProfiles/updateCropProfile",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.put("/v4/config/crop-profile", data);

      if (response) {
        notify("Crop profile updated", { type: "success" });
        thunkAPI.dispatch(getCropProfiles());
        return response;
      } else {
        notify("Failed to update", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to update");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleCropProfile = createAsyncThunk(
  "cropProfiles/getSingleCropProfile",
  async (cropId, thunkAPI) => {
    try {
      const cropProfile = await axiosServices.get(
        `/v3/config/crop-profile/${cropId}`
      );
      return cropProfile?.result?.crop_profile;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getCropProfiles = createAsyncThunk(
  "cropProfiles/getCropProfiles",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const cropProfiles = await axiosServices.get(
        `/v3/config/crop-profile${queryString}`
      );

      // if (cropProfiles?.result?.crop_profiles?.data) {
      //   return {
      //     data: cropProfiles?.result?.crop_profiles?.data,
      //     total: cropProfiles?.result?.crop_profiles?.total,
      //     totalCategories: cropProfiles?.result?.count_categories
      //   };
      // } else {
      //   return thunkAPI.rejectWithValue("Failed to fetch crop profiles");
      // }
      return cropProfiles
        ? cropProfiles?.result
        : thunkAPI.rejectWithValue("Failed to fetch crop profiles");
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getDraftedCropProfiles = createAsyncThunk(
  "cropProfiles/getDraftedCropProfiles",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const drafts = await axiosServices.get(
        `/v4/config/crop-profile/drafts${queryString}`
      );
      return drafts?.result;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleDraftedCropProfile = createAsyncThunk(
  "cropProfiles/getSingleDraftedCropProfile",
  async (draftId = "", thunkAPI) => {
    try {
      const draft = await axiosServices.get(
        `/v4/config/crop-profile/drafts/${draftId}`
      );
      return draft?.result?.draft;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const deleteCropProfile = createAsyncThunk(
  "cropProfiles/deleteCropProfile",
  async (cropProfileId, thunkAPI) => {
    try {
      const response = await axiosServices.delete(
        `/v3/config/crop-profile/${cropProfileId}`
      );

      if (response && response.success) {
        notify("Crop profile deleted", { type: "success" });
        thunkAPI.dispatch(getCropProfiles());
        return response;
      } else {
        notify("Failed to delete", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to delete crop profile");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const addNewCategory = createAsyncThunk(
  "categories/addNewCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v4/config/category", data);

      if (response) {
        notify("Crop Category created", { type: "success" });
        return response;
      } else {
        notify("Failed", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to add new category");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async ({ category_id, ...data }, thunkAPI) => {
    try {
      const response = await axiosServices.patch(
        `/v4/config/category/${category_id}`,
        data
      );

      if (response) {
        notify("Crop category updated", { type: "success" });
        return response;
      } else {
        notify("Failed to update category", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to update category");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getSingleCategory = createAsyncThunk(
  "categories/getSingleCategory",
  async (categoryId = "", thunkAPI) => {
    try {
      const category = await axiosServices.get(
        `/v4/config/category/${categoryId}`
      );
      return category?.result?.category;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getActivityCategories = createAsyncThunk(
  "categories/getActivityCategories",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v3/config/categories${queryString}`
      );
      const success = response.success;
      const data = response.result?.data;

      if (success && data) {
        return {
          data,
          total: data?.length || 0,
        };
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to fetch activity categories");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getCategoriesByCrop = createAsyncThunk(
  "categories/getCategoriesByCrop",
  async ({ cropId = "", queryParams = {} }, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v4/config/categories/${cropId}&${queryString}`
      );
      return response?.result;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getDefaultTask = createAsyncThunk(
  "tasks/getDefaultTask",
  async (cropId = "", thunkAPI) => {
    try {
      const task = await axiosServices.get(
        `/v4/config/crop_profile/${cropId}/default/task`
      );
      return task?.result?.task;
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getCropCalendar = createAsyncThunk(
  "tasks/getCropCalendar",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v3/config/activities/tasks${queryString}`
      );
      const success = response.success;
      const data = response.result?.data;

      if (success && data) {
        return data;
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to fetch crop calendar");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const addNewTask = createAsyncThunk(
  "tasks/addNewTask",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post(
        "/v4/config/activity/task",
        data
      );

      if (response) {
        notify("Task created", { type: "success" });
        return response;
      } else {
        notify(response?.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to add new task");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const updateTask = createAsyncThunk(
  "tasks/updateTask",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosServices.patch(`/v3/config/task/${id}`, data);

      if (response?.success) {
        notify("Task updated", { type: "success" });
        return response;
      }

      notify("Failed to update task", { type: "error" });
      return thunkAPI.rejectWithValue("Failed to update task");
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosServices.delete("/v3/config/task", {
        data: payload,
      });

      if (response.success) {
        notify("Task deleted successfully", { type: "success" });
        return response;
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to delete task");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const getFarmSeassons = createAsyncThunk(
  "seasons/getFarmSeassons",
  async (queryParams = {}, thunkAPI) => {
    try {
      const queryString = createQuery(queryParams);
      const response = await axiosServices.get(
        `/v3/config/seasons${queryString}`
      );
      const success = response.success;
      const data = response.result?.data?.data;

      if (success && data) {
        return {
          data,
          total: response.result?.data?.total,
        };
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to fetch farm seasons");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const addNewSeason = createAsyncThunk(
  "seasons/addNewSeason",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v3/config/season", data);

      if (response) {
        notify("Season created", { type: "success" });
        thunkAPI.dispatch(getFarmSeassons());
        return response;
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to add new season");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const startSeason = createAsyncThunk(
  "seasons/startSeason",
  async (data, thunkAPI) => {
    try {
      const url = data.season_id
        ? "/v4/config/restart/season"
        : "/v4/config/start/season";
      // console.log(data, url);
      const response = await axiosServices.post(url, data);

      if (response) {
        notify("Season started successfully", { type: "success" });
        thunkAPI.dispatch(getFarmSeassons());
        return response;
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to start season");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);

export const toggleSeason = createAsyncThunk(
  "seasons/toggleSeason",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.put("/v3/config/season", data);

      if (response) {
        notify("Season updated successfully", { type: "success" });
        thunkAPI.dispatch(getFarmSeassons());
        return response;
      } else {
        notify(response.message || "An error occured", { type: "error" });
        return thunkAPI.rejectWithValue("Failed to toggle season");
      }
    } catch (error) {
      errorHandler(error);
      throw error;
    }
  }
);
