import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosServices from "../../services/axiosServices";
import { notify } from "../../utils/toastNotification";
import { createQuery } from "../../utils/createQuery";
import { errorHandler } from "../../utils/actionsErrorHandler";


export const addInput = createAsyncThunk(
  "input/addInput",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v3/input-types", data);
      // console.log(response);

      if (response?.success) {
        notify("Input created", { type: "success" });
        thunkAPI.dispatch(getInputs({}));
      }
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to add input");
      throw error;
    }
  }
);

export const getInputs = createAsyncThunk(
  "input/getInputs",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.get(
        `/v3/input-tracks${createQuery(queryParams)}`
      );
      // // console.log(cropProfiles);
      const success = response.success;
      const inputTypes = response?.result?.new_inputs;

      if (success && inputTypes) {
        console.log(inputTypes);
        // dispatch({
        //   type: GET_INPUTS_SUCCESS,
        //   payload: {
        //     data: inputTypes.data,
        //     total: inputTypes.total,
        //   },
        // });
      }

      return inputTypes;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

// {{url_agro}}/v4/new-inputs?per_page=10&page=1 (get)
export const getAllNewInputs = createAsyncThunk(
  "input/getAllNewInputs",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.get(
        `/v4/new-inputs${createQuery(queryParams)}`
      );

      console.log({ response });

      return response?.result?.new_inputs || [];
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

export const createNewInputs = createAsyncThunk(
  "input/createNewInputs",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.post(
        `/v4/new-inputs${createQuery(queryParams)}`
      );
      // // console.log(cropProfiles);
      const success = response.success;
      const inputTypes = response.result?.inputs_tracked;

      if (success && inputTypes) {
        console.log(inputTypes);
        // dispatch({
        //   type: GET_INPUTS_SUCCESS,
        //   payload: {
        //     data: inputTypes.data,
        //     total: inputTypes.total,
        //   },
        // });
      }

      return inputTypes;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);



// {{url_agro}}/v4/new-inputs
export const getNewInputs = createAsyncThunk(
  "input/getNewInputs",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.get(
        `/v4/new-inputs${createQuery(queryParams)}`
      );
      // console.log(response);
      const success = response.success;
      const newInputs = response.result;

      if (success && newInputs) {
        console.log(newInputs);
      }

      return newInputs;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

// {{url_agro}}/v4/all/inputs/distribution
export const getInputsDistribution = createAsyncThunk(
  "input/getInputsDistribution",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.get(
        `/v4/all/inputs/distribution${createQuery(queryParams)}`
      );
      // console.log(response);
      const success = response.success;
      const inputsDistribution = response.result;

      if (success && inputsDistribution) {
        console.log(inputsDistribution);
      }

      return inputsDistribution;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

// {{url_agro}}/v4/input-categories
export const getInputCategories = createAsyncThunk(
  "input/getInputCategories",
  async (queryParams = {}, { dispatch }) => {
    try {
      const response = await axiosServices.get(
        `/v4/input-categories${createQuery(queryParams)}`
      );
      // console.log(response);
      const success = response.success;
      const categories = response.result?.categories;

      if (success && categories) {
        console.log(categories);
      }

      return categories;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      throw error;
    }
  }
);

// {{url_agro}}/v4/input-categories (add)
export const addInputCategory = createAsyncThunk(
  "input/addInputCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.post("/v4/input-categories", data);
      // console.log(response);

      if (response?.success) {
        notify(response?.message, { type: "success" });
        thunkAPI.dispatch(getInputCategories({}));
      }
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to add input category");
      throw error;
    }
  }
);

// {{url_agro}}/v4/input-categories/1 (delete)
export const deleteInputCategory = createAsyncThunk(
  "input/deleteInputCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosServices.delete(`/v4/input-categories/${data.id}`);
      console.log(response);

      if (response?.success) {
        notify(response?.message, { type: "success" });
        thunkAPI.dispatch(getInputCategories({}));
      }
    } catch (error) {
      console.log(error);
      errorHandler(error, "Failed to delete input category");
      throw error;
    }
  }
);

export const deleteInput = (data) => async (dispatch) => {
  try {
    const response = await axiosServices.delete(`/v3/input-types/${data.id}`);
    // console.log(response);

    if (response?.success) {
      notify("Input deleted", { type: "success" });
      dispatch(getInputs({}));
    } else {
      notify(response.message || "An error occured", { type: "error" });
    }
  } catch (error) {
    console.log(error);
    errorHandler(error, "Failed to delete input");
    return error;
  }
};

export const getAssociations = createAsyncThunk(
  "input/getAssociations",
  async (queryParams = {}, thunkAPI) => {
    try {
      const response = await axiosServices.get(
        `/v3/fetch/associations${createQuery(queryParams)}`
      );
      // console.log(response);
      const success = response.success;
      const associations = response.result?.associations;

      if (success && associations) {
        console.log(associations);
        // dispatch({
        //   type: GET_ASSOCIATIONS_SUCCESS,
        //   payload: {
        //     data: associations,
        //     total: associations.length,
        //   },
        // });
      }

      return associations;
    } catch (error) {
      console.log(error);
      errorHandler(error);
      return error;
    }
  }
);
